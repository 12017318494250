@font-face{
    font-family: 'hankengrotesk_bold';
    src:url(./HankenGrotesk-Bold.ttf) format(truetype)
} 

@font-face{
    font-family: 'hankengrotesk_reguler';
    src:url(./HankenGrotesk-Regular.ttf) format(truetype)
} 

@font-face{
    font-family: 'american_typwriter_reguler';
    src:url(./American\ Typewriter\ Regular.ttf) format(truetype)
} 

.detail{
        color: #BCA0DC;
        padding-left: 20px;
        padding-right: 10px;

}

.cadet{
    font-family: 'hankengrotesk_bold';
}

.dbirth{
    font-size: 18px;
    font-family: 'american_typwriter_reguler';
}

.year{
    width: fit-content;
    margin-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 3px;
    padding-bottom: 3px;
    border: 1px;
    border-width: 2px;
    border-style: solid;    
    border-color: #a57fc8;
    border-radius: 20px;
    font-family: 'hankengrotesk_reguler';
    
}

.description{
    font-family: 'american_typwriter_reguler';
}

.about{
    font-family: 'hankengrotesk_reguler';

}

.list{
    font-family: 'american_typwriter_reguler';
    opacity: 34%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: left;
    align-items: left;
    padding: 10px;
}


.music-container {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #52307c;
    width: 100%;
    padding: 5px;
}

.music_button {
    background-color: #52307c;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    box-shadow: 0 2px 4px rgb(115, 89, 151);
    transition: all 0.3s ease-in-out;
}

.music_button svg {
    width: 24px;
    height: 24px;
    color: #755297;
    fill: #755998;
}

.music_button:hover {
    background-color: #755998;
}

.music-container.clicked .music_button {
    margin-top: 5px;
    fill: #755998;
}