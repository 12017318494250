body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.home-container {
  background-size: cover;
  background-position: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-attachment: fixed;
}

.link-left {
  width: 180px;
  height: 500px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  text-decoration: none;
}

.link-right {
  width: 180px;
  height: 500px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  text-decoration: none;
}
