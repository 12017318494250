@font-face{
    font-family: 'hankengrotesk_bold';
    src:url(../details/HankenGrotesk-Bold.ttf) format(truetype)
} .header{    
    color: #BCA0DC;
    background: none;
    border: none;
    opacity: 22%;
    font-size: 40px;
    font-family: 'hankengrotesk_bold';
    text-decoration: none;
    
}